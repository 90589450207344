import React, {
  useEffect,
  useState,
  useContext,
  useCallback,
  createContext,
} from 'react';
import { nanoid } from 'nanoid';
import {
  CardContextData,
  CardContextProps,
  CardProps,
  newFilterProps,
} from './types';

import { toast } from 'react-toastify';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { FilterContext } from '~/context/filter';

import { Color } from 'react-color';

import { format } from 'date-fns';

import {
  deleteCard,
  getCards,
  getCardsUsuario,
  getFiltrosCard,
  getFiltrosObrigatorios,
  postNewCard,
  updateCard,
  updateCardWithoutFilter,
  getFiltrosCardOriginal,
} from './service/api';
import { getUserData } from '~/services/user';
import { useQueryClient } from 'react-query';
import { useForm } from 'react-hook-form';
import { validDiffFiltros } from '~/utils/validationDiffFiltros';
import useDebounce from '~/hooks/useDebounce';
import { loadingContext } from '~/context/loading';
import { useTableViewManager } from '~/context/tableViewManager';

const MySwal = withReactContent(Swal);

export const CardContext = createContext({} as CardContextData);
export function CardContextProvider({
  codTelaProp,
  children,
  handleSearch,
  showTable,
  onShowTable,
  isOpenModal,
  setIsOpenModal,
  isUpdateCard,
  setUpdateCard,
  BackSearchProps,
  setBackSearchProps,
  setDataEdit,
  setIsCardFixed,
  isCardFixed,
  setLoading,
  textFiltro,
  tipoFiltro,
}: CardContextProps): JSX.Element {
  const user = getUserData();
  const { loadingCard, setLoadingCard } = useContext(loadingContext);
  const { debouncedFn } = useDebounce();
  const [cards, setCards] = useState<CardProps[]>([]);
  const [cardsFiltered, setCardsFiltered] = useState<CardProps[]>([]);
  const [fetchCard, setFetchCard] = useState<boolean>(false);

  const [codCard, setCodCard] = useState<number | undefined>(undefined);
  const [textColor, setTextColor] = useState<Color>('#000000');
  const [isOpenPickerColor, setisOpenPickerColor] = useState<boolean>(false);
  const [showCards, setShowCards] = useState<boolean>(false);
  const [tipoAcesso, setTipoAcesso] = useState<number>(0);
  const [codUsuario, setCodUsuario] = useState<number | undefined>(0);

  const queryClient = useQueryClient();
  const {
    onChangeUpdateGrid,
    updateGrid,
    viewCardSelected,
    findViewByCardClicked,
    onResetAllStates,
    codVisao,
    onChangeCodCardSelected,
  } = useTableViewManager();

  const {
    register,
    getValues,
    setValue,
    control,
    watch,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  const getViewCard = useCallback(async () => {
    if (codCard) await findViewByCardClicked(codCard, codTelaProp);
  }, [codCard, codTelaProp, findViewByCardClicked]);

  useEffect(() => {
    getViewCard();
  }, []);

  useEffect(() => {
    if (!showTable || !isOpenModal) return;

    const description = getValues('name');
    const visao =
      description.length > 0 && viewCardSelected
        ? { label: viewCardSelected.label, value: viewCardSelected.value }
        : { label: 'Nenhuma', value: null };

    setValue('visao', visao);
  }, [codCard, getValues, isOpenModal, setValue, showTable, viewCardSelected]);

  useEffect(() => {
    if (showTable && isOpenModal && updateGrid) {
      setTimeout(() => {
        onChangeUpdateGrid(false);
        onChangeCodCardSelected(null);
      }, 1000);
    }
  }, [
    isOpenModal,
    onChangeCodCardSelected,
    onChangeUpdateGrid,
    showTable,
    updateGrid,
  ]);

  const colorWatch = watch('color');

  const {
    AddFilterOrUpdate,
    filtersContexto,
    RemoveAllFilter,
    AddFilterDefault,
    filterDefauts,
  } = useContext(FilterContext);

  useEffect(() => {
    handleFilterCard(cards);
  }, [textFiltro]);

  const handleFilterCard = useCallback(
    async (Valcard: any) => {
      setCardsFiltered([]);

      let cardFilter = [];
      if (textFiltro) {
        cardFilter = Valcard.filter((card: any) =>
          card.des_card
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .includes(
              textFiltro
                .toLowerCase()
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, ''),
            ),
        );
        setCardsFiltered(cardFilter);
      } else {
        setCardsFiltered(Valcard);
      }
    },
    [textFiltro],
  );

  // Busca os cards no API
  useEffect(() => {
    getCardsTela();

    if (!showTable) {
      setShowCards(true);
    }
  }, [codTelaProp]);

  useEffect(() => {
    if (!isUpdateCard) {
      handleClearCard();
    }
  }, [isUpdateCard]);

  useEffect(() => {
    if (BackSearchProps) {
      getCardsTela();
    }
  }, [BackSearchProps]);

  useEffect(() => {
    setValue('color', '#FEFEFE');
    const cardEdit = filtersContexto.find(
      (item: any) => item.codTela === codTelaProp,
    );

    if (!codCard) {
      const codCardOnSearch: any =
        queryClient.getQueryData(`infoCardAtivo_${codTelaProp}`) || undefined;

      setCodCard(cardEdit?.cod_card);
      setValue('name', cardEdit?.name_card || '');
      setValue('color', cardEdit?.cor || '#FEFEFE');
      if (codCardOnSearch) {
        setCodUsuario(codCardOnSearch.cod_usuario);
      }
    }
  }, []);

  useEffect(() => {
    (async () => {
      const filters = filtersContexto.filter(
        (item: any) => item.codTela === codTelaProp,
      );
      setLoading(true);
      if (filters.length === 0) {
        const { data } = await getCardsUsuario(codTelaProp);
        const card = data;
        if (card) {
          if (card.cod_card === card.cod_card_fixado) {
            onChangeCodCardSelected(card.cod_card_fixado);
          }

          setIsCardFixed(card.cod_card === card.cod_card_fixado);
          const infoCard: any = {
            cod_card: card.cod_card,
            des_card: card.des_card,
            cor: card.cor,
            cor_texto: card.cor_texto,
            cod_card_fixado: card.cod_card_fixado,
            cod_usuario: card.cod_usuario,
          };
          queryClient.setQueryData(`infoCardAtivo_${codTelaProp}`, infoCard);
          await getFieldsCards(
            card.cod_card,
            card.cod_card === card.cod_card_fixado,
            card.des_card,
            card.cor,
            card.cor_texto,
            card.cod_card_fixado,
            card.tipo_acesso,
            card.cod_usuario,
          );
        }
      }
      setLoading(false);
    })();
  }, []);

  const getFilterContextTela = useCallback(() => {
    return (
      filtersContexto.find((item: any) => item.codTela === codTelaProp)
        ?.itens || []
    );
  }, [codTelaProp, filtersContexto]);

  const Loading = useCallback(() => {
    debouncedFn(() => setLoading(false), showTable ? 1400 : 500);
  }, [showTable, setLoading, debouncedFn]);

  const returnOnSeachDefault = useCallback(async () => {
    setLoading(true);
    queryClient.removeQueries(`infoCardAtivo_${codTelaProp}`);
    await RemoveAllFilter(codTelaProp);

    onShowTable(false);
    setUpdateCard(false);
    setShowCards(true);
    setIsCardFixed(false);
    Loading();
  }, [
    queryClient,
    Loading,
    RemoveAllFilter,
    codTelaProp,
    onShowTable,
    setLoading,
    setUpdateCard,
    setIsCardFixed,
  ]);

  const handleClearCard = useCallback(() => {
    const codCardOnSearch: any =
      queryClient.getQueryData(`infoCardAtivo_${codTelaProp}`) || undefined;

    if (!codCardOnSearch) {
      setCodCard(undefined);
      setValue('color', '');
      setTextColor('#000000');
      setValue('name', '');
      setIsCardFixed(false);
      setTipoAcesso(0);
    }

    clearErrors('name');
  }, [setIsCardFixed, setValue, codTelaProp, queryClient, clearErrors]);

  const returnSearch = useCallback(async () => {
    const { name, color } = getValues();
    if (!showTable) {
      return;
    }

    const cardColor = color === '' ? '#FEFEFE' : color;

    setShowCards(true);

    const filtersOld: any[] = filterDefauts.filter(
      (item) => item.codTela === codTelaProp,
    );

    const filtersProps = getFilterContextTela();

    let verifyEditFilters;
    if (filtersOld[0] && filtersOld[0].itens) {
      verifyEditFilters = await validDiffFiltros(
        filtersOld[0].itens,
        filtersProps,
      );
    }

    if (!verifyEditFilters && isUpdateCard) {
      await MySwal.fire({
        title: ``,
        text: `A pesquisa "${name}" foi alterada!`,
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonColor: '#0065FF',
        cancelButtonColor: '#ccc',
        showDenyButton: true,
        confirmButtonText: 'Salvar Alterações',
        cancelButtonText: 'Cancelar',
        denyButtonText: 'Descartar',
      }).then(async (result) => {
        if (result.isConfirmed) {
          if (!(filtersProps.length > 0)) {
            setBackSearchProps(false);
            toast.warning(`Você deve selecionar ao menos um filtro.`);
            return;
          }
          const data = await updateCard(
            codCard,
            name,
            cardColor,
            textColor,
            filtersProps,
            isCardFixed,
            tipoAcesso,
            codTelaProp,
          );

          if (data.success) {
            returnOnSeachDefault();
            setIsOpenModal(false);
            setBackSearchProps(false);
            toast.success('Consulta alterada com sucesso!');
          }
          setTimeout(async () => {
            const getCardsVal = await getCards(codTelaProp, tipoFiltro);

            setCards(getCardsVal.data);
            setCardsFiltered(getCardsVal.data);
            RemoveAllFilter(codTelaProp);
          }, 500);
        }
        if (result.isDenied) {
          returnOnSeachDefault();
          setIsCardFixed(false);
        }

        if (result.isDismissed) {
          setBackSearchProps(false);
        }
      });
    } else {
      returnOnSeachDefault();
      setBackSearchProps(false);
      setUpdateCard(false);
      handleClearCard();
    }
  }, [
    returnOnSeachDefault,
    codCard,
    codTelaProp,
    filterDefauts,
    isUpdateCard,
    setBackSearchProps,
    setIsCardFixed,
    setIsOpenModal,
    showTable,
    isCardFixed,
    textColor,
    getValues,
    getFilterContextTela,
    handleClearCard,
    setUpdateCard,
    RemoveAllFilter,
    tipoFiltro,
    tipoAcesso,
  ]);

  const getFieldsCardWhenReturnOnClick = useCallback(
    async (
      cod_card: number,
      name_card: string,
      cor: string,
      cor_texto: string,
      cod_card_fixado: number,
      cod_usuario: number,
    ) => {
      setLoading(true);
      setUpdateCard(true);
      setCodCard(cod_card);
      setValue('name', name_card);
      setValue('color', cor);
      setTextColor(cor_texto);
      setCodUsuario(cod_usuario);
      setDataEdit({
        cod_card,
        des_card: name_card,
        cor,
      });
      setIsCardFixed(cod_card_fixado === cod_card);
      Loading();
    },
    [Loading, setValue, setDataEdit, setIsCardFixed, setLoading, setUpdateCard],
  );
  const getCardsTela = useCallback(async () => {
    setCards([]);
    setLoadingCard(true);

    const { data } = await getCards(codTelaProp, tipoFiltro);

    setShowCards(true);

    debouncedFn(async () => {
      const isReturnOnRowclick: any =
        queryClient.getQueryData(`cacheLastSearch_${codTelaProp}`) || undefined;
      const codCardOnSearch: any =
        queryClient.getQueryData(`infoCardAtivo_${codTelaProp}`) || undefined;

      if (isReturnOnRowclick || codCardOnSearch) {
        if (!BackSearchProps && codCardOnSearch && !getValues('name')) {
          getFieldsCardWhenReturnOnClick(
            codCardOnSearch.cod_card,
            codCardOnSearch.des_card,
            codCardOnSearch.cor,
            codCardOnSearch.cor_texto,
            codCardOnSearch.cod_card_fixado,
            codCardOnSearch.cod_usuario,
          );
          setShowCards(false);
        }
      } else {
        setCards(data);
        setCardsFiltered(data);
      }
    }, 100);

    setCards(data);
    setCardsFiltered(data);
    handleFilterCard(data);
    setLoadingCard(false);

    if (BackSearchProps) await returnSearch();
  }, [
    BackSearchProps,
    codTelaProp,
    returnSearch,
    queryClient,
    getFieldsCardWhenReturnOnClick,
    getValues,
    debouncedFn,
    tipoFiltro,
    setLoadingCard,
    handleFilterCard,
  ]);

  const handleSubmitCardUpdate = useCallback(
    async (codigo_card: number) => {
      const { name, color, visao } = getValues();
      const cardColor = color === '' ? '#FEFEFE' : color;

      const filtersAtivos = getFilterContextTela();

      if (!showTable && filtersAtivos.length === 0) {
        const data = await updateCardWithoutFilter(
          codigo_card,
          name,
          cardColor,
          textColor,
          isCardFixed,
          codTelaProp,
          tipoAcesso,
          visao.value,
        );

        if (data.success) {
          toast.success(data.message);
          setFetchCard(!fetchCard);
          getCardsTela();
          setIsOpenModal(false);
        }
      } else {
        const data = await updateCard(
          codigo_card,
          name,
          cardColor,
          textColor,
          filtersAtivos,
          isCardFixed,
          tipoAcesso,
          codTelaProp,
          visao.value,
        );

        if (data.success) {
          toast.success(data.message);
          const defaultFilterCard = filtersAtivos.map((item: any) => {
            return {
              index: item.index,
              cod_campo: item.cod_campo,
              cod_tipo_dados: item.cod_tipo_dados,
              cod_tipo_info: item.cod_tipo_info,
              condicao: item.condicao,
              des_valor: item.des_valor,
            };
          });

          const infoCard: any = {
            cod_card: codigo_card,
            des_card: name,
            cor: cardColor,
            cor_texto: textColor,
            cod_card_fixado: isCardFixed ? codigo_card : null,
            cod_usuario: user.id,
          };

          queryClient.removeQueries(`infoCardAtivo_${codTelaProp}`);
          queryClient.setQueryData(`infoCardAtivo_${codTelaProp}`, infoCard);

          setDataEdit({
            cod_card: codigo_card,
            des_card: name,
            cor: color,
          });
          AddFilterDefault({ codTela: codTelaProp, itens: defaultFilterCard });
          setFetchCard(!fetchCard);
          getCardsTela();
          setIsOpenModal(false);
        }
      }
    },
    [
      AddFilterDefault,
      codTelaProp,
      fetchCard,
      isCardFixed,
      setIsOpenModal,
      showTable,
      textColor,
      getCardsTela,
      setDataEdit,
      getValues,
      queryClient,
      getFilterContextTela,
      tipoAcesso,
      user.id,
    ],
  );

  async function handleSubmitCard() {
    const { name, color, visao } = getValues();
    const cardColor = color === '' ? '#FEFEFE' : color;
    const filtersProps = getFilterContextTela();

    const codCardOnSearch: any = queryClient.getQueryData(
      `infoCardAtivo_${codTelaProp}`,
    );

    if (name === '') {
      setError('name', { type: 'focus' });
      toast.warning('O nome da pesquisa deve ser informado');
      return;
    }

    if (filtersProps.length === 0 && showTable) {
      toast.warning('Você deve selecionar ao menos um filtro.');
      return;
    }

    if (tipoFiltro !== 0 && codCard && !codCardOnSearch) {
      const filtrosCard = await getFiltrosCardOriginal(codCard);
      await handleDuplicaCard(filtrosCard);
      return;
    }

    onChangeUpdateGrid(true);
    const codigo_card = codCard || codCardOnSearch?.cod_card;
    onChangeCodCardSelected(codigo_card);

    if (isUpdateCard || codCardOnSearch) {
      handleSubmitCardUpdate(codigo_card);
    } else {
      const cod_visao = codVisao === 0 ? null : codVisao;
      const { data, success, message } = await postNewCard(
        codTelaProp,
        name,
        cardColor,
        textColor,
        user?.usuario,
        filtersProps,
        isCardFixed,
        tipoAcesso,
        visao.value ?? cod_visao,
      );

      if (success) {
        toast.success(message);
        const defaultFilterCard = filtersProps.map((item: any) => {
          return {
            index: item.index,
            cod_campo: item.cod_campo,
            cod_tipo_dados: item.cod_tipo_dados,
            cod_tipo_info: item.cod_tipo_info,
            condicao: item.condicao,
            des_valor: item.des_valor,
          };
        });

        AddFilterDefault({ codTela: codTelaProp, itens: defaultFilterCard });
        setFetchCard(!fetchCard);

        const infoCard: any = {
          cod_card: data.cod_card,
          des_card: name,
          cor: cardColor,
          cor_texto: textColor,
          cod_card_fixado: isCardFixed ? data.cod_card : null,
          cod_usuario: user.id,
        };

        queryClient.setQueryData(`infoCardAtivo_${codTelaProp}`, infoCard);
        setDataEdit({
          cod_card: data.cod_card,
          des_card: name,
          cor: cardColor,
        });
        setIsOpenModal(false);

        setCodCard(data.cod_card);
        await getCardsTela();
        if (showTable) {
          await RemoveAllFilter(codTelaProp);
          await AddFilterOrUpdate({
            codTelaProp,
            edit: true,
            cod_card: data.cod_card,
            name_card: name,
            cor: cardColor,
            itens: filtersProps,
          });
        }

        debouncedFn(() => setUpdateCard(true), 1000);
      }
    }
    onResetAllStates(true);
  }

  async function handleDeleteCard() {
    try {
      MySwal.fire({
        title: ``,
        text: 'Deseja realmente excluir essa consulta?',

        showCancelButton: true,
        confirmButtonColor: '#07289e',
        cancelButtonColor: '#ff7b7b',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Cancelar',
      }).then(async (result) => {
        if (result.isConfirmed) {
          if (codCard) {
            const { success, message } = await deleteCard(codCard);
            if (success) {
              queryClient.removeQueries(`infoCardAtivo_${codTelaProp}`);
              await RemoveAllFilter(codTelaProp);
              setIsOpenModal(false);
              onShowTable(false);
              setUpdateCard(false);
              setFetchCard(!fetchCard);
              handleClearCard();
              getCardsTela();

              onResetAllStates();

              toast.success(message);
            }
          }
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  const newFilterBlock = (item: any, Filters: any[]) => {
    const IdGenerator = nanoid();

    const newFilter: newFilterProps = {
      index: IdGenerator,
      cod_tipo_dados: item.cod_tipo_dados,
      des_nome: item.des_campo,
      des_valor: null,
      condicao: 0,
      isBlock: true,
      nome_bd: item.nome_bd,
      cod_tipo_info: item.cod_tipo_info,
      cod_campo: item.cod_campo,
      expressao_validacao: item.expressao_validacao,
      listagem_info: item.listagem_info,
      tipo_expressao: item.tipo_expressao,
    };

    switch (item.cod_tipo_dados) {
      case 1:
        newFilter.des_valor = format(new Date(), 'yyyy-MM-dd');
        Filters.push(newFilter);
        break;

      case 2:
        newFilter.des_valor = format(new Date(), 'yyyy-MM-dd HH:mm');
        Filters.push(newFilter);
        break;

      case 3 || 4:
        newFilter.des_valor = undefined;
        Filters.push(newFilter);
        break;
      case 5:
        newFilter.des_valor = undefined;
        newFilter.condicao = item.cod_tipo_info > 0 ? 0 : 4;
        Filters.push(newFilter);
        break;
      case 6:
        newFilter.index = item.flg_filtro_obrigatorio
          ? item.cod_campo
          : newFilter.index;
        newFilter.des_valor = 'false';
        Filters.push(newFilter);
        break;

      default:
        break;
    }
  };

  const createNewFilters = (data: any) => {
    const filters = data.data.map((item: any) => {
      const IdGenerator = nanoid();
      try {
        if (item.condicao === 1 && item.condicao_2 === 2) {
          return {
            ...item,
            des_nome: item.des_campo,
            index: IdGenerator,
            isBlock: false,
            des_valor: [item.des_valor, item.des_valor_2],
            condicao: 6,
          };
        }
        return {
          ...item,
          isBlock: false,
          des_valor:
            item.condicao === 7 || item.condicao === 8
              ? undefined
              : JSON.parse(item.des_valor),
          des_nome: item.des_campo,
          index: IdGenerator,
        };
      } catch (erro: any) {
        MySwal.fire({
          title:
            'Os campos ou critérios relacionados à essa consulta foram atualizados.',
          text: 'Ela não pode mais ser realizada da maneira como foi criada. \n A consulta será removida.',
          icon: 'warning',
        });
        return 'invalido';
      }
    });

    return filters;
  };

  const removeInvalidFilter = useCallback(
    async (newFilters: any) => {
      const ExistFilterInvalido = newFilters.find(
        (item: any) => item === 'invalido',
      );
      if (ExistFilterInvalido) {
        if (codCard) await deleteCard(codCard);
        setFetchCard(!fetchCard);
        getCardsTela();
      }
    },
    [codCard, fetchCard, getCardsTela],
  );

  const addFilterDefaultCard = useCallback(
    (newFilters: any) => {
      const defaultFilterCard = newFilters.map((item: any) => {
        return {
          index: item.index,
          cod_campo: item.cod_campo,
          cod_tipo_dados: item.cod_tipo_dados,
          cod_tipo_info: item.cod_tipo_info,
          condicao: item.condicao,
          des_valor: item.des_valor,
        };
      });
      AddFilterDefault({ codTela: codTelaProp, itens: defaultFilterCard });
    },
    [AddFilterDefault, codTelaProp],
  );

  // Busca campos de determinado card, e aplica pesquisa
  const getFieldsCards = useCallback(
    async (
      cod_card: number,
      searchExec = false,
      name_card: string,
      cor: string,
      cor_texto: string,
      cod_card_fixado: number | undefined,
      tipo_acesso: number,
      cod_usuario?: number,
    ) => {
      setLoading(true);
      const filtrosCard = await getFiltrosCard(cod_card);
      const filtersBlock = await getFiltrosObrigatorios(codTelaProp);

      setUpdateCard(true);

      if (filtrosCard.data) {
        const newFilters = await createNewFilters(filtrosCard);
        await removeInvalidFilter(newFilters);

        if (filtersBlock.success && filtersBlock.data) {
          // eslint-disable-next-line no-restricted-syntax
          for await (const filterBlock of filtersBlock.data) {
            const index = newFilters.findIndex(
              (item: any) => item.cod_campo === filterBlock.cod_campo,
            );
            if (index !== -1) {
              newFilters[index].isBlock = true;
            } else {
              newFilterBlock(filterBlock, newFilters);
            }
          }
        }
        addFilterDefaultCard(newFilters);
        setCodCard(cod_card);
        setValue('name', name_card);
        setValue('color', cor);
        setTipoAcesso(tipo_acesso);
        setCodUsuario(cod_usuario);
        setTextColor(cor_texto);
        setDataEdit({
          cod_card,
          des_card: name_card,
          cor,
        });
        setIsCardFixed(cod_card_fixado === cod_card);
        await RemoveAllFilter(codTelaProp);
        await AddFilterOrUpdate({
          codTela: codTelaProp,
          edit: name_card.length > 0,
          cod_card,
          name_card,
          cor,
          itens: newFilters,
        });
        if (searchExec) {
          await handleSearch();
          await Loading();
        }
      }

      setLoading(false);
    },
    [
      AddFilterOrUpdate,
      RemoveAllFilter,
      addFilterDefaultCard,
      codTelaProp,
      handleSearch,
      removeInvalidFilter,
      setDataEdit,
      setIsCardFixed,
      setLoading,
      setUpdateCard,
      Loading,
      setValue,
    ],
  );

  // Chama modal de edição de card
  const handleEditCard = async (card: CardProps) => {
    setIsCardFixed(card.cod_card === card.cod_card_fixado);
    setCodCard(card.cod_card);
    setTipoAcesso(card.tipo_acesso);
    setCodUsuario(card.cod_usuario);
    setUpdateCard(tipoFiltro === 0);
    setValue('name', card.des_card);
    setValue('color', card.cor);
    setValue('visao', { label: card.des_visao, value: card.cod_visao });
    onChangeCodCardSelected(null);
    setIsOpenModal(true);
  };

  const handleChangeTipoAcesso = (value: any) => {
    setTipoAcesso(value);
  };

  const handleDuplicaCard = async (filtrosCard: any) => {
    const { name, color, visao } = getValues();
    const cardColor = color === '' ? '#FEFEFE' : color;
    const defaultFilterCard = filtrosCard.data.map((item: any) => {
      return {
        cod_tipo_info: item.cod_tipo_info,
        des_valor:
          item.des_valor === '"true"' || item.des_valor === '"false"'
            ? item.des_valor.replace(/"/g, '')
            : JSON.parse(item.des_valor),
        cod_campo: item.cod_campo,
        condicao: item.tipo_condicao,
      };
    });
    const { data, success, message } = await postNewCard(
      codTelaProp,
      name,
      cardColor,
      textColor,
      user?.usuario,
      defaultFilterCard,
      isCardFixed,
      tipoAcesso,
      visao.value ?? codVisao,
      codCard,
    );

    if (success) {
      toast.success(message);
      setIsOpenModal(false);
      setCodCard(data.cod_card);

      const infoCard: any = {
        cod_card: codCard,
        des_card: name,
        cor: cardColor,
        cor_texto: textColor,
        cod_card_fixado: isCardFixed ? codCard : undefined,
        tipo_acesso: tipoAcesso,
        cod_usuario: user.id,
      };

      queryClient.setQueryData(`infoCardAtivo_${codTelaProp}`, infoCard);

      getFieldsCards(
        data.cod_card,
        true,
        name,
        cardColor,
        String(textColor),
        isCardFixed ? codCard : undefined,
        tipoAcesso,
      );
    }
  };

  return (
    <CardContext.Provider
      value={{
        codTelaProp,
        showTable,
        cards,
        queryClient,
        getFieldsCards,
        handleEditCard,
        isUpdateCard,
        isCardFixed,
        setIsCardFixed,

        textColor,
        setTextColor,
        isOpenModal,
        setIsOpenModal,
        fetchCard,
        handleDeleteCard,
        handleSubmitCard,
        handleClearCard,
        setisOpenPickerColor,
        isOpenPickerColor,
        showCards,
        register,
        errors,
        setValue,
        control,
        getValues,
        colorWatch,
        clearErrors,
        setUpdateCard,
        cardsFiltered,
        loadingCard,
        setTipoAcesso,
        tipoAcesso,
        handleChangeTipoAcesso,
        codCard,
        setCodCard,
        tipoFiltro,
        codUsuario,
      }}
    >
      {children}
    </CardContext.Provider>
  );
}

export const useCards = (): CardContextData => {
  return useContext(CardContext);
};
