import React, { useContext, useEffect } from 'react';
import { BsPinFill } from 'react-icons/bs';

import { FilterContext } from '~/context/filter';
import { ColorContainer } from './Components/ColorContainer';
import { BoxColorPreview } from './Components/BoxColorPreview';
import { useCards } from '../CardContext';
import { BootstrapModal } from './styles';
import Button from '@atlaskit/button';

import { InputText } from '~/components/NovosInputs';
import { HotKeys, configure } from 'react-hotkeys';
import { useHotkeys } from 'react-hotkeys-hook';
import { TiposAcessos } from './Components/TiposAcessos';
import useDebounce from '~/hooks/useDebounce';
import { getUserData } from '~/services/user';
import { DefaultView } from './Components/DefaultView';

export const ModalCard: React.FC = () => {
  const {
    showTable,
    setUpdateCard,
    isUpdateCard,
    isCardFixed,
    setIsCardFixed,
    codTelaProp,
    isOpenModal,
    setIsOpenModal,
    handleDeleteCard,
    handleSubmitCard,
    handleClearCard,
    isOpenPickerColor,
    setisOpenPickerColor,
    queryClient,
    register,
    errors,
    setValue,
    control,
    colorWatch,
    clearErrors,
    codCard,
    setCodCard,
    codUsuario,
  } = useCards();

  const user = getUserData();
  const { debouncedFn } = useDebounce();

  configure({
    ignoreTags: ['input', 'select', 'textarea', 'button'],
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ignoreEventsCondition(keyEvent: KeyboardEvent) {
      return false;
    },
  });

  const { RemoveAllFilter } = useContext(FilterContext);

  useEffect(() => {
    const codCardOnSearch: any =
      queryClient.getQueryData(`infoCardAtivo_${codTelaProp}`) || undefined;
    if (codCardOnSearch) {
      setUpdateCard(true);
      setValue('name', codCardOnSearch.des_card);
      setValue('color', codCardOnSearch.cor);
    }

    debouncedFn(() => {
      const input = document.querySelector('[name="name"]') as HTMLInputElement;
      input?.focus();
    }, 500);

    // focus
  }, [isOpenModal]);

  useHotkeys(
    'ctrl+alt+s',
    (event) => {
      event.preventDefault();
      if (isOpenModal) {
        handleSubmitCard();
      }
    },
    [],
  );

  const keyMap = {
    hkSave: 'ctrl+alt+s',
  };

  const keyHandlers = {
    hkSave: async () => {
      if (isOpenModal) {
        handleSubmitCard();
        return false;
      }
    },
  };

  return (
    <HotKeys id="hotkeys" keyMap={keyMap} handlers={keyHandlers} tabIndex={-1}>
      <BootstrapModal
        show={isOpenModal}
        onClick={(e: any) => {
          setisOpenPickerColor(false);
        }}
        onHide={() => {
          setIsOpenModal(false);

          if (!showTable) {
            setUpdateCard(false);
            setCodCard(undefined);
            handleClearCard();
          }

          if (!codCard) {
            setUpdateCard(false);
            handleClearCard();
          }
        }}
        style={{ marginTop: '25px' }}
        centered
        size="xl"
      >
        <BootstrapModal.Header>
          <BoxColorPreview
            color={colorWatch}
            setColorPreview={(cor) => setValue('color', cor)}
            setShouldOpenPicker={setisOpenPickerColor}
            shouldOpenPicker={isOpenPickerColor}
          />

          <div className="textFieldContainer">
            <div>
              <InputText
                maxLength={50}
                label="Descrição"
                caseInput="upper"
                placeholder="Digite aqui a Descrição"
                name="name"
                control={control}
                toLowerCase={false}
                register={register}
                onBlur={(e) => {
                  debouncedFn(() => {
                    if (e.target.value.length > 0) {
                      clearErrors('name');
                    }
                  }, 600);
                }}
                isError={!!errors.name}
              />
            </div>
          </div>
        </BootstrapModal.Header>
        <BootstrapModal.Body>
          <DefaultView
            register={register}
            control={control}
            setValue={setValue}
            cod_tela={codTelaProp}
          />
          <TiposAcessos />
          <br />
          <span>Escolha uma cor:</span>
          <div className="pickerColorContainer">
            <ColorContainer
              setPickerColor={(cor: any) => {
                setValue('color', String(cor));
              }}
            />
          </div>
        </BootstrapModal.Body>
        <BootstrapModal.Footer>
          <div className="containerLeft">
            <Button
              onClick={() => {
                setIsCardFixed(!isCardFixed);
              }}
              appearance="subtle"
            >
              <BsPinFill
                id="fixarcard"
                title={isCardFixed ? 'Desfixar Consulta' : 'Fixar Consulta'}
                style={{
                  color: isCardFixed ? '#FF9900' : '#7C7C7C',
                  marginRight: '15px',
                }}
              />
              <label htmlFor="fixarcard">Fixar essa consulta?</label>
            </Button>
          </div>
          <div
            className="containerRight"
            style={{ minWidth: isUpdateCard ? '250px' : '150px' }}
          >
            <Button
              onClick={() => {
                handleSubmitCard();
              }}
              appearance="primary"
              data-toggle="tooltip"
              title="CTRL + ALT + S"
            >
              Salvar
            </Button>
            {isUpdateCard && codUsuario === user.id && (
              <Button
                onClick={() => {
                  if (showTable === false) {
                    RemoveAllFilter(codTelaProp);
                  }
                  handleDeleteCard();
                }}
                appearance="danger"
              >
                Excluir
              </Button>
            )}
            <Button
              onClick={() => {
                if (!showTable) {
                  setCodCard(undefined);
                  setUpdateCard(false);
                }
                handleClearCard();
                setIsOpenModal(false);
              }}
              appearance="subtle"
            >
              Cancelar
            </Button>
          </div>
        </BootstrapModal.Footer>
      </BootstrapModal>
    </HotKeys>
  );
};
